import React from 'react';

export default function Question({ question, options, onAnswer, selectedAnswer }) {
    return (
        <div>
            <div className="text-lg font-semibold mb-4">{question}</div>
            <div className="space-y-2">
                {options.map((option, index) => (
                    <label
                        key={index}
                        className="flex items-center space-x-3 cursor-pointer"
                    >
                        <input
                            type="radio"
                            name="answer"
                            value={option}
                            checked={selectedAnswer === option}
                            onChange={() => onAnswer(option)}
                            className="h-4 w-4 border-gray-00 focus:ring-indigo-500"
                        />
                        <span className="text-gray-900">{option}</span>
                    </label>
                ))}
            </div>
        </div>
    );
}