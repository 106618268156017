import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { submitUserPhoto } from '../../services/apiService';

export default function PhotoCapture({ quizUuid, onSubmitPhoto }) {
    const webcamRef = useRef(null);
    const [capturedImage, setCapturedImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const capturePhoto = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setCapturedImage(imageSrc);
    };

    const submitPhoto = async () => {
        if (capturedImage) {
            setLoading(true);

            try {
                await submitUserPhoto(capturedImage, quizUuid);
                onSubmitPhoto(); // Notify parent component that photo submission is complete
            } catch (error) {
                console.error("Error submitting photo:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const retakePhoto = () => {
        setCapturedImage(null); // Clear the captured image to retake
    };

    return (
        <div className="flex flex-col items-center">
            {capturedImage ? (
                <img src={capturedImage} alt="Captured" className="mb-4 rounded" />
            ) : (
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/png"
                    className="w-full max-w-sm rounded"
                />
            )}
            <div className="mt-4 space-x-4">
                {capturedImage ? (
                    <>
                    <button
                        onClick={submitPhoto}
                        disabled={loading}
                        className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-500"
                    >
                        {loading ? "Uploading..." : "Submit Photo"}
                    </button>
                    <button
                            onClick={retakePhoto}
                            className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-500"
                        >
                            Retake Photo
                    </button>
                    </>
                ) : (
                    <button
                        onClick={capturePhoto}
                        className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-500"
                    >
                        Capture Photo
                    </button>
                )}
            </div>
        </div>
    );
}