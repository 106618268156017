import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
    const [progress, setProgress] = useState(0);
    const [stepProgress, setStepProgress] = useState(0);
    const [result, setResult] = useState(null);
    const [resultImage, setResultImage] = useState(null);
    const [showProgress, setShowProgress] = useState(false);

    const socket = io('https://159.65.183.200:5000', {
        transports: ['websocket'],
        pingInterval: 25000,   // Match server-side ping_interval
        pingTimeout: 120000    // Match server-side ping_timeout
    });

    const resetWebSocketState = () => {
        setProgress(0); // Reset progress
        setResult(null); // Clear result
        setShowProgress(false); // Hide progress bar
    };

    useEffect(() => {
        socket.on('connect', () => {
            console.log("Connected to WebSocket server");
        });

        // Listen for connection errors
        socket.on('connect_error', (err) => {
            console.error("WebSocket connection error:", err);
        });

        // Listen for disconnect events
        socket.on('disconnect', () => {
            console.log("Disconnected from WebSocket server");
        });

        // WebSocket listeners for progress and result updates
        socket.on('progress_update', (data) => {
            const taskPercentage = Math.round((data.completed_tasks / data.total_tasks) * 100);
            const stepPercentage = Math.round((data.current_step / data.total_steps) * 100);
            setProgress(taskPercentage);
            setStepProgress(stepPercentage);
        });

        socket.on('result_complete', (data) => {
            setResult(data.result);
            setResultImage(data.image)
            setShowProgress(false);
            socket.off('progress_update');
            socket.off('result_complete');
        });

        // Cleanup WebSocket listeners on unmount
        return () => {
            socket.off('progress_update');
            socket.off('result_complete');
        };
    }, [socket]);

    return (
        <WebSocketContext.Provider value={{ progress, stepProgress, result, resultImage, showProgress, setShowProgress, resetWebSocketState }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);