import React, { useState, useEffect } from 'react';
import { createQuiz, getQuestion, sendSelectedAnswer} from '../../services/apiService';
import Question from './Question';
import PhotoCapture from './PhotoCapture';
import { useWebSocket } from '../../services/WebSocketContext';
import ProgressBar from './ProgressBar';

export default function Quiz({ userId, onResetUser }) {
    const [quizUuid, setQuizUuid] = useState(null);
    const [questionData, setQuestionData] = useState({ question: '', options: [] });
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [photoCaptured, setPhotoCaptured] = useState(false);
    const { progress, result, resultImage, showProgress, setShowProgress, resetWebSocketState } = useWebSocket();
    

    const initializeQuiz = async () => {
        setLoading(true);
        setQuizUuid(null);
        setQuestionData({ question: '', options: [] });
        setSelectedAnswer(null);
        setQuestionIndex(0);
        setIsAnswerSubmitted(false);
        resetWebSocketState();
        setShowProgress(false);
        setPhotoCaptured(false);

        const quizId = await createQuiz({ user_uuid: userId, theme: 'default' });
        setQuizUuid(quizId);
        setLoading(false);
    };

    useEffect(() => {
        initializeQuiz();
    }, [userId]);

    const loadNextQuestion = async () => {
        setLoading(true);
        const { question, answer_a, answer_b, answer_c, answer_d } = await getQuestion(quizUuid);
        setQuestionData({ question, options: [answer_a, answer_b, answer_c, answer_d] });
        setIsAnswerSubmitted(false);  // Reset the submission state
        setSelectedAnswer(null);  // Clear previous answer selection
        setLoading(false);
    };

    useEffect(() => {
        if (quizUuid && questionIndex < 5) loadNextQuestion();
        else if (quizUuid && questionIndex === 5)
        {
            fetchResult();
        }
    }, [quizUuid, questionIndex]);

    const handleAnswerSelection = (answer) => {
        setSelectedAnswer(answer);  // Track the selected answer
    };

    const handleSubmitAnswer = async() => {
        if (selectedAnswer) {
            // Process the answer here (e.g., send to backend if needed)
            console.log("User selected:", selectedAnswer);
            setIsAnswerSubmitted(true);  // Mark answer as submitted
            await sendSelectedAnswer({quiz_uuid:quizUuid, answer:selectedAnswer});
            setQuestionIndex((prevIndex) => prevIndex + 1);  // Move to the next question
        }
    };

    const fetchResult = async () => {
        setShowProgress(true); // Show progress bar
        const response = await fetch("http://159.65.183.200:5000/quiz/generate_result", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ quiz_uuid: quizUuid, user_uuid: userId })
        });
        //const data = await response.json();
    };

    return (
        <div>
            {loading ? (
                // Loading Screen
                <div className="flex items-center justify-center h-64">
                    <p className="text-lg font-semibold text-gray-700">Loading...</p>
                </div>
            ) : !photoCaptured ? (
                // Photo Capture Step
                <PhotoCapture quizUuid={quizUuid} onSubmitPhoto={() => setPhotoCaptured(true)} />
            ) : showProgress ? (
                // Progress Bar
                <ProgressBar />
            ) : result ? (
                // Result Display with Image
                <div className="flex flex-col space-y-8" >
                <div className="result p-4">
                    <h2 className="text-2xl font-bold mb-4">Your Tech Entrepreneur RPG Character</h2>
                    {resultImage && (
                        <img src={resultImage} alt="Quiz Result" className="mb-4 rounded-lg shadow-md" />
                    )}
                    <p className="text-gray-800 whitespace-pre-line py-8">{result}</p>
                    <button
                        onClick={() => {initializeQuiz(); onResetUser();}}
                        className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-4
                         text-lg font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                        Restart
                    </button>
                </div>
                </div>
            ) : (
                // Quiz Questions and Submit Button
                <div class="flex flex-col space-y-8">
                    <Question
                        question={questionData.question}
                        options={questionData.options}
                        onAnswer={handleAnswerSelection}
                        selectedAnswer={selectedAnswer}
                    />

                    <button
                        onClick={handleSubmitAnswer}
                        disabled={!selectedAnswer || isAnswerSubmitted}
                        className="flex w-full  justify-center rounded-md bg-indigo-600 px-3 py-4 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Submit Answer
                    </button>

                </div>
            )}
        </div>
    );
}