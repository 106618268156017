import React, { useState, useEffect } from 'react';
import { useWebSocket } from '../../services/WebSocketContext';
export default function ProgressBar() {
    const { progress, stepProgress, showProgress } = useWebSocket();

    if (!showProgress) return null;

    return (
        <div className="flex flex-col items-center">
            <div className="w-full max-w-md my-4">
                <div className="h-4 bg-gray-200 rounded">
                    <div style={{ width: `${progress}%` }} className="h-full bg-indigo-600 rounded"></div>
                </div>
                <p className="text-sm text-gray-700 mt-2">Loading... {progress}%</p>
            </div>
            <div className="w-full max-w-md my-4">
                <div className="h-4 bg-gray-200 rounded">
                    <div style={{ width: `${stepProgress}%` }} className="h-full bg-blue-600 rounded"></div>
                </div>
                <p className="text-sm text-gray-700 mt-2">Step Progress... {stepProgress}%</p>
            </div>
        </div>
    );
}