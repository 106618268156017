import React, { useState } from 'react';

export default function AccountForm({ onCreateAccount }) {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // New state for error message

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isValid = await verifyEmail(email);
        if (!isValid) {
            setErrorMessage("Please enter a valid email address."); // Display error message if email is invalid
            return;
        }

        setErrorMessage(''); // Clear any existing error
        onCreateAccount({ email, name, company });
    };
    
    const verifyEmail = async (email) => {
        try {
            const response = await fetch(`https://emailvalidation.abstractapi.com/v1/?api_key=351fdc22e5df436894fd4ccc6a22c782&email=${email}`);
            const data = await response.json();
            console.log(data); // Log the response data to understand the structure

            // Check if the email is valid based on Abstract API's response structure
            return data.is_valid_format.value; // Adjusted to match the correct field
        } catch (error) {
            console.error("Email verification error:", error);
            return false;
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex place-content-center">
            <div class="flex place-content-center w-6/12  " className="w-6/12 space-y-6">
            <div>
                <label htmlFor="email" className="block text-lg font-medium text-gray-900">
                    Email address
                </label>
                <div className="mt-2">
                    <input
                        id="email"
                        name="email"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    />
                </div>
                {errorMessage && <p className="text-red-500 mt-1">{errorMessage}</p>} {/* Error message display */}
            </div>

            <div>
                <label htmlFor="name" className="block text-lg font-medium text-gray-900">
                    First Name
                </label>
                <div className="mt-2">
                    <input
                        id="name"
                        name="name"
                        type="text"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    />
                </div>
            </div>

            <div>
                <label htmlFor="company" className="block text-lg font-medium text-gray-900">
                    Company
                </label>
                <div className="mt-2">
                    <input
                        id="company"
                        name="company"
                        type="text"
                        value={company}
                        onChange={e => setCompany(e.target.value)}
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    />
                </div>
            </div>

            <div className="flex py-8">
                <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px- py-4 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Sign in
                </button>
                </div>
            </div>
                </div>
        </form>
    );
}
