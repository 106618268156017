// Login.jsx
import React, { useState } from 'react';
import RpgQuiz from './RpgQuiz';
import './App.css'; // Import the CSS file

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();

    const correctEmail = 'Quiz';
    const correctPassword = 'Pixelegion';

    if (email === correctEmail && password === correctPassword) {
      setIsLoggedIn(true);
    } else {
      alert('Incorrect email or password.');
    }
  };

  return (
    <div className="login-container">
      {!isLoggedIn ? (
        <form onSubmit={handleLogin} className="login-form">
          <h2>Login</h2>
          <label htmlFor="username" className="block text-lg font-medium text-gray-900">
                    Username
          </label>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
          />
           <label htmlFor="username" className="block text-lg font-medium text-gray-900">
                    Password
          </label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
          />
          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-indigo-600 px- py-4 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Login
          </button>
        </form>
      ) : (
        <RpgQuiz />
      )}
    </div>
  );
};

export default Login;